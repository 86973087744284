import React from 'react'
import './info.css'
import {
    InfoContainer,
    InfoWrapper,
    InfoRow,
    Column1,
    TextWrapper,
    TopLine,
    Heading,
    SubTitle,
    SubTitle2,
    Column2,
    ImgWrap,
    
} from "./InfoSectionElements"

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function InfoSection({lightBg,id,imgStart,topLine,lightText,headLine,darkText,img,alt,description,description2,description3,description4,description5,description6}) {
    return (
        <>
        <InfoContainer lightBg={lightBg} id={id}>
            <InfoWrapper>
                <InfoRow imgStart={imgStart}>
                    <Column1>
                        <TextWrapper>
                            <TopLine>{topLine}</TopLine>
                            <Heading lightText={lightText}>{headLine}</Heading>
                            <SubTitle darkText={darkText}>{description}</SubTitle>
                            <SubTitle darkText={darkText}>{description2}</SubTitle>
                            <SubTitle2 darkText={darkText}>{description3}</SubTitle2>
                            <SubTitle2 darkText={darkText}>{description4}</SubTitle2>
                            <SubTitle2 darkText={darkText}>{description5}</SubTitle2>
                            <SubTitle darkText={darkText}>{description6}</SubTitle>
                        </TextWrapper>
                    </Column1> 
                    <Column2>
                        <ImgWrap>
                            <LazyLoadImage src={img} alt={alt} effect="blur" className='infoImage'/>
                        </ImgWrap>
                    </Column2>    
                </InfoRow>    
            </InfoWrapper>    
        </InfoContainer> 
        </>
    )
}

export default InfoSection
