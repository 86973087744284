import styled from 'styled-components'
import { Link as LinkR } from 'react-router-dom'
import { Link as LinkS} from 'react-scroll'

export const Nav = styled.nav`
    background:${({ scrollNav }) => (scrollNav ? '#000':'transparent')};
    color:#fff;
    height: 80px;
    margin-top: -80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size:1rem;
    position:sticky;
    top:0;
    z-index: 10;

    @media screen and (max-width:960px){
        transition: 0.8s all ease;
    }
`;

export const NavbarContainer = styled.div`
    display: flex;
    justify-items:space-between;
    height: 80px;
    z-index: 1;
    width: 100%;
    padding:0 24px;
    max-width: 1100px;
`;

export const NavLogo = styled(LinkR)`
    color:#fff;
    justify-items: flex-start;
    cursor: pointer;
    font-size: 1rem;  
    display:flex;
    align-items: center;
    font-weight: bold;
    text-decoration: none;
`;
export const Img = styled.img`
    height:80px;  
    width:80px;
`;
export const MobileIcon = styled.div`
    display:none;

    @media screen and (max-width:768px){
        display:block;
        position:absolute;
        top:0;
        right:0;
        transform:translate(-100%,60%);
        font-size: 1.5rem;
        cursor: pointer;
    }
`;

export const NavMenu = styled.ul`
    display:flex;
    align-items:center;
    list-style:none;
    text-align:center;
    padding-left:550px;

    @media screen and (max-width:768px){
        display:none;
    }
`;

export const NavItem = styled.li`
    height:80px;
    
`;

/* export const NavLinks = styled.li` */
export const NavLinks = styled(LinkS)`   
    color:#fff;
    display: flex;
    align-items: center;
    text-decoration:none;
    padding:0 1rem;
    height:100%;
    cursor: pointer;
    margin: 0 18px;
    padding: 0;
    font-size: 18px;
    line-height: 40px;

    &.active{
        // border-bottom:2px solid #FF0000; 
        color:#FF0000;
    }
`;

export const NavBtn = styled.nav`
    display:flex;
    align-items:center;
    padding-left:350px;

    @media screen and (max-width:768px){
        display:none;
    }
`;

export const NavBtnLink = styled(LinkS)`
    border-radius:0px;
    background:#84ba3f;
    white-space:nowrap;
    padding:10px 22px;
    color:#010606;
    font-size:16px;
    outline:none;
    border:none;
    cursor:pointer;
    transition:all 0.2s ease-in-out;
    text-decoration:none;

    &:hover{
        transition:all 0.2s ease-in-out;
        background:#84ba3f;
        color:#FF0000;
    }
`;

