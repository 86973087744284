export const AboutObj ={
    id:'about',
    lightBg:true,
    lightText:false,
    lightTextDesc:false,
    topLine:'',
    headLine:"Rafah Group of Companies.",
    description:"Incepted in 2010, Rafah group of companies leverages on its expertise to deliver solutions and services in Technology, Building & Construction and Energy",
    description2:"Rafah Group is currently having three (3) subsidiary companies:",
    description3:"1. Rafah Communications Limited ",
    description4:"2. Rafah Builders Company Limited",
    description5:"3. Rafah Energy Limited",
    description6:"with their main operations in Uganda but recently expanding business operations into South Sudan, Kenya & Tanzania",
    buttonLabel:"Get Started",
    imgStart:true,
    img:require('../../images/group.svg').default,
    alt:'group',
    dark:false,
    primary:false,
    darkText:true
}
