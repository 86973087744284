import styled from "styled-components"
import {MdKeyboardArrowRight,MdArrowForward} from "react-icons/md"

export const HeroContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: 800px;
    position: relative;
    z-index:1;

    :before{
        content:'';
        position:absolute;
        top:0;
        right:0;
        left:0;
        bottom:0;
        background: rgba(0, 0, 0, 0.7); content: ""; height: 100%; left: 0; position: absolute; top: 0; width: 100%; z-index: 0; 
        z-index:2;
    }
    
`;

export const HeroBg = styled.div`
    position: absolute;
    top:0;
    right: 0;
    bottom: 0;
    left:0;
    width: 100%;
    height:100%;
    overflow: hidden;
`;
export const ImgBg = styled.img`
    width:100%;
    height:100%;
    -o-object-fit: cover;
    object-fit: cover;
    opacity: 1;
    background-position:center;
    background-size: cover;
    
`;

export const HeroContent = styled.div`
    z-index:3;
    max-width:700px;
    position: absolute;
    padding: 8px 24px;
    display:flex;
    flex-direction:column;
    align-items:center;
    text-align:center;
`;

export const HeroH1 = styled.h1`
    font-family: "Trade Gothic", "Roboto", sans-serif;
    font-weight: 400;
    color: #fff;
    letter-spacing: 3px;
    text-transform: uppercase;
    font-style: normal;

    @media screen and (max-width:480px){
        font-size: 24px; 
    }
`;

export const HeroP = styled.p`
    margin-top:0px;
    color:#666;
    font-size:18px;
    height:50px;
    text-align:center;

    @media screen and (max-width:768px){
        font-size: 18px;
    }

    @media screen and (max-width:480px){
        font-size: 18px;
    }
`;

export const HeroBtnWrapper = styled.div`
    margin-top: 32px;
    display:flex;
    flex-direction: column;
    align-items: center;
`;

export const ArrowForward = styled(MdArrowForward)`
    margin-left:8px;
    font-size:20px;
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
    margin-left:8px;
    font-size:20px;
`;