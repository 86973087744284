import styled from "styled-components"

export  const ProjectContainer = styled.div`
    height:500px;
    display:flex;
    flex-direction:column; 
    justify-content: center;  
    align-items:center;
    background:#929B4A;
`;

export  const ProjectWrapper = styled.div`
    max-width:1000px;

`;
export  const ProjectH1 = styled.h1`
   color: #f00;
    font-size: 18px;
    line-height: 16px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 45px;

   @media  screen and (max-width:480px){
        font-size:2rem;
    } 
`;