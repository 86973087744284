export const AboutObj ={
    id:'about',
    lightBg:true,
    lightText:false,
    lightTextDesc:false,
    topLine:'',
    headLine:"Website Design & Development",
    description:"We design and develop highly responsive websites ranging from static, dynamic & E-commerce sites.",
    description2:"We offer highly professional website designing & management packages with up to date website solutions good enough to meet the fast evolving technology standards in the world.",
    buttonLabel:"Get Started",
    imgStart:true,
    img:require('../../images/web.svg').default,
    alt:'office',
    dark:false,
    primary:false,
    darkText:true
}
