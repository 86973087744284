import React from 'react'
import Fade from "react-reveal/Fade"
// import F18 from '../../../images/f18.png'
import "./paint.css"
import Paint1 from '../../../images/paint1.jpeg'
import Paint2 from '../../../images/paint2.jpeg'
import Paint3 from '../../../images/waterproof.jpg'
import Paint4 from '../../../images/painthouse.jpg'

import {
    PaintContainer,
    PaintH1,
    PaintWrapper
}from './PaintElements' 

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Paint = () => {
    return (
        <PaintContainer>
            <PaintH1>Paint</PaintH1>
                <PaintWrapper>
                 <Fade bottom cascade>
                 <div class="paint-card">
                    <div class="paint-tumb">
                        <LazyLoadImage src={Paint1} alt="icon" className='imageLoadPaint' effect='blur'/>
                    </div>
                    {/* <div class="paint-details">
                        <span class="paint-catagory">F18 is an innovative biometric fingerprint reader for access control application</span>
                        <h4><a href="rafahgroup">CCTV Cameras</a></h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vero, possimus nostrum!</p>
                        <div class="paint-bottom-details">
                            <div class="paint-price"><small>$96.00</small>$230.99</div>
                            <div class="paint-links">
                                <a href="rafahgroup"><i class="fa fa-heart"></i></a>
                                <a href="rafahgroup"><i class="fa fa-shopping-cart"></i></a>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div class="paint-card">
                    <div class="paint-tumb">
                        <LazyLoadImage src={Paint2} alt="icon"  className='imageLoadPaint' effect='blur'/>
                    </div>
                    {/* <div class="paint-details">
                        <span class="paint-catagory">F18 is an innovative biometric fingerprint reader for access control application</span>
                        <h4><a href="rafahgroup">ZKTeco F18 </a></h4>
                     <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vero, possimus nostrum!</p>
                        <div class="paint-bottom-details">
                            <div class="paint-price"><small>$96.00</small>$230.99</div>
                            <div class="paint-links">
                                <a href="rafahgroup"><i class="fa fa-heart"></i></a>
                                <a href="rafahgroup"><i class="fa fa-shopping-cart"></i></a>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div class="paint-card">
                    <div class="paint-tumb">
                        <LazyLoadImage src={Paint3} alt="icon"  className='imageLoadPaint' effect='blur'/>
                    </div>
                    {/* <div class="paint-details">
                        <span class="paint-catagory">F18 is an innovative biometric fingerprint reader for access control application</span>
                        <h4><a href="rafahgroup">Walk Through</a></h4>
                         <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vero, possimus nostrum!</p>
                        <div class="paint-bottom-details">
                            <div class="paint-price"><small>$96.00</small>$230.99</div>
                            <div class="paint-links">
                                <a href="rafahgroup"><i class="fa fa-heart"></i></a>
                                <a href="rafahgroup"><i class="fa fa-shopping-cart"></i></a>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div class="paint-card">
                    <div class="paint-tumb">
                    <LazyLoadImage src={Paint4} alt="icon" className='imageLoadPaint' effect='blur'/>
                    </div>
                    {/* <div class="paint-details">
                        <span class="paint-catagory">F18 is an innovative biometric fingerprint reader for access control application</span>
                        <h4><a href="rafahgroup">Fire Systems</a></h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vero, possimus nostrum!</p>
                        <div class="paint-bottom-details">
                            <div class="paint-price"><small>$96.00</small>$230.99</div>
                            <div class="paint-links">
                                <a href="rafahgroup"><i class="fa fa-heart"></i></a>
                                <a href="rafahgroup"><i class="fa fa-shopping-cart"></i></a>
                            </div>
                        </div> 
                    </div> */}
                </div>
                 </Fade>
                </PaintWrapper>
        </PaintContainer>
    )
}

export default Paint
