import React from 'react';
import './rafahprojects.css'
import image1 from '../../../images/nssf.jpeg'
import image2 from '../../../images/nssfjinja.jpeg'
import image3 from '../../../images/nssfkla.jpeg'
import image4 from '../../../images/planning.jpeg'
import image5 from '../../../images/ucc.jpeg'
import image6 from '../../../images/securityhouse.jpeg'
import {ProjectH1} from './RafahProjectsElements'
const RafahProjects = () => {
  return <div className='containerx' id='projects'>
      <ProjectH1>Completed Projects</ProjectH1>
      <div className='wrapper'>
      <div className='box'>
          <div className='imgBx'>
            <img alt="title" src={image1}/>
          </div>
          <div className='content'>
              <div>
                  <h2>Mbarara city house</h2>
                    <p>
                      Supply and Installation of CCTV Cameras and Intruder Alarm Systems
                    </p>
              </div>
          </div>
      </div>
      <div className='box'>
          <div className='imgBx'>
            <img alt="title1" src={image2}/>
          </div>
          <div className='content'>
              <div>
                  <h2>JINJA CITY HOUSE</h2>
                    <p>
                      Supply and Installation of CCTV Cameras and Intruder Alarm Systems
                    </p>
              </div>
          </div>
      </div>
      <div className='box'>
          <div className='imgBx'>
            <img alt="title2" src={image3}/>
          </div>
          <div className='content'>
              <div>
                  <h2>WORKER'S HOUSE</h2>
                    <p>
                      Supply and Installation of CCTV Cameras and Intruder Alarm Systems
                    </p>
              </div>
          </div>
      </div>
      <div className='box'>
          <div className='imgBx'>
            <img alt="title3" src={image4}/>
          </div>
          <div className='content'>
              <div>
                  <h2>National Planning House</h2>
                    <p>
                      Local Area Network and Electrical setup
                    </p>
              </div>
          </div>
      </div>
      <div className='box'>
          <div className='imgBx'>
            <img alt="title3" src={image5}/>
          </div>
          <div className='content'>
              <div>
                  <h2>UCC House</h2>
                    <p>
                      Supply and Installation of CCTV Cameras and Intruder Alarm Systems
                    </p>
              </div>
          </div>
      </div>
      <div className='box'>
          <div className='imgBx'>
            <img alt="title3" src={image6}/>
          </div>
          <div className='content'>
              <div>
                  <h2>social security house</h2>
                    <p>
                      Supply and Installation of CCTV Cameras and Intruder Alarm Systems
                    </p>
              </div>
          </div>
      </div>
      </div>
  </div>;
};

export default RafahProjects;
