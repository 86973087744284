export const AboutObj ={
    id:'about',
    lightBg:true,
    lightText:false,
    lightTextDesc:false,
    topLine:'',
    headLine:"Who We Are...",
    description:"Incepted in 2010, Rafah Communications Limited is an Information Technology firm providing competitively priced products and services to companies across of Video Surveillance and Closed-Circuit Television (CCTV), Alarm, Biometric and Fire Systems. These systems are designed to suit individual customer requirements.",
    description2:"Our industry experience & in-depth understanding of advanced security technologies & telecommunication systems enable us supply products that can best meet specific & the most demanding requirements of our customers. When you deal with Rafah Communications, you are guaranteed high quality products & services. We help customers find the right solution at the right price. Rafah Communications has presence in Uganda, Kenya and Tanzania",
    buttonLabel:"Get Started",
    imgStart:true,
    img:require('../../../images/profile.jpg'),
    alt:'office',
    dark:false,
    primary:false,
    darkText:true
}
