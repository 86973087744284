import ImageOne from '../images/carousel.jpg'
import ImageTwo from '../images/carousel2.jpg'
import ImageThree from '../images/carousel2.jpg'
import ImageFour from '../images/carousel3.jpg'
import ImageFive from '../images/carousel5.jpg'
import ImageSix from '../images/carousel6.jpg'

export const SliderData = [
    {
        title:'Giving your home a new look for both interior and exterior.',
        path:'/projects',
        label:'View Home',
        Image:ImageOne,
        alt:'rafahbuilders'
    },
    {
        title:'Giving your home a new look for both interior and exterior.',
        path:'/projects',
        label:'View Home',
        Image:ImageTwo,
        alt:'rafahbuilders'
    },
    {
        title:'Giving your home a new look for both interior and exterior.',
        path:'/projects',
        label:'View Home',
        Image:ImageThree,
        alt:'rafahbuilders'
    },
    {
        title:'Giving your home a new look for both interior and exterior.',
        path:'/projects',
        label:'View Home',
        Image:ImageFour,
        alt:'rafahbuilders'
    },
    {
        title:'Giving your home a new look for both interior and exterior.',
        path:'/projects',
        label:'View Home',
        Image:ImageFive,
        alt:'rafahbuilders'
    },
    {
        title:'Giving your home a new look for both interior and exterior.',
        path:'/projects',
        label:'View Home',
        Image:ImageSix,
        alt:'rafahbuilders'
    }
]