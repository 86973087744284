import styled from "styled-components"
import {MdKeyboardArrowRight,MdArrowForward} from "react-icons/md"
import { Link as LinkS} from 'react-scroll'

export const HeroContainer = styled.div`
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 0 30px;
    height: 300px;
    position: relative;
    z-index:1;
    :before{
        content:'';
        position:absolute;
        top:0;
        right:0;
        left:0;
        bottom:0;
        background: rgba(0, 0, 0, 0.7); content: ""; height: 100%; left: 0; position: absolute; top: 0; width: 100%; z-index: 0; 
        z-index:2;
    }
    
`;

export const HeroBg = styled.div`
    position: absolute;
    top:0;
    right: 0;
    bottom: 0;
    left:0;
    width: 100%;
    height:100%;
    overflow: hidden;
`;
export const ImgBg = styled.img`
    width:100%;
    height:100%;
    -o-object-fit: cover;
    object-fit: cover;
    opacity: 1;
    background-position:center;
    background-size: cover;
    
`;

export const HeroContent = styled.div`
    z-index:3;
    max-width:900px;
    position: absolute;
    padding: 8px 24px;
    display:flex;
    flex-direction:column;
    align-items:left;
    text-align: left!important;
`;

export const HeroH1 = styled.h1`
    color: rgba(198,201,216,.75);
    font-size: 25px;
    text-transform: uppercase;
    letter-spacing: 4px;
    display: block;
    margin-bottom: 20px;
    @media screen and (max-width:480px){
        font-size: 24px; 
    }
`;

export const HeroP = styled.p`
    margin-top:150px;
    color:#fff;
    height:150px;
    text-align: center!important;
    font-weight:900;
    line-height: 68px;
    font-size: 50px;
    @media screen and (max-width:768px){
        font-size: 18px;
    }

    @media screen and (max-width:480px){
        font-size: 18px;
    }
`;

export const HeroBtnWrapper = styled.div`
    margin-top: 52px;
    display:flex;
    flex-direction: column;
    align-items: left;
    justify-content:center;
    max-width:150px; 
`;
export const HeroButton = styled(LinkS)`
    background:${({primary})=>(primary ? "transparent":"rgb(233, 32, 79)")};  
    white-space:nowrap;
    padding:${({big})=>(big ? '14px 48px':'12px 30px')};
    color:${({dark})=>(dark ? "#fff":"transparent")};
    font-size:${({fontBig})=>(fontBig ? '20px':'16px')};
    outline:none;
    cursor:pointer;
    display: flex;
    justify-content:left; 
    align-items:center;
    transition: all 0.2s ease-in-out;
    text-decoration:none;
    
    font-family: Overpass, sans-serif;
    font-size: 19px;
    font-weight: 600;
    line-height: 12px;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(233, 32, 79);
    padding: 20px 20px;

    &:hover{
        transition: all 0.2s ease-in-out;
        background:${({primary})=>(primary ? "rgb(233, 32, 79)":"transparent")};
        text-decoration:none;
    }
`;
export const ArrowForward = styled(MdArrowForward)`
    margin-left:8px;
    font-size:20px;
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
    margin-left:8px;
    font-size:20px;
`;