export const AboutObj ={
    id:'about',
    lightBg:true,
    lightText:false,
    lightTextDesc:false,
    topLine:'',
    headLine:"Electrical Installation",
    description:"Our 24/7 services team are capable of resolving multiple electrical tasks, they have built a solid reputation within the domestic market, with customer satisfaction, guaranteed. Our teams of qualified electric- ians ensure that every job, no matter the size and complexity is carried out in a safe and professional manner. Our trained technicians are capable of solving the most complex of electrical issues, they have built a solid reputat- ion within the commercial market, with client satisfaction, guaranteed. We have vast experience in the Electrical Engineering Services in Restaurants, Supermarkets, Banks, Shops and Offices.",
    description2:"We design and develop iOS & Android applications that provode a great mobile app experience.",
    buttonLabel:"Get Started",
    imgStart:true,
    img:require('../../images/electricalinst.jpeg'),
    alt:'office',
    dark:false,
    primary:false,
    darkText:true
}
