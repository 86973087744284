export const AboutObj ={
    id:'about',
    lightBg:true,
    lightText:false,
    lightTextDesc:false,
    topLine:'',
    headLine:"Mobile App Development",
    description:"Turn your Idea into a Dream and we turn it into Reality. Rafah Communications we are the official developers of Aspire Vacancies App & Saber App. We are here to execute your brilliant idea",
    description2:"We design and develop iOS & Android applications that provode a great mobile app experience.",
    buttonLabel:"Get Started",
    imgStart:true,
    img:require('../../images/app-development.png'),
    alt:'office',
    dark:false,
    primary:false,
    darkText:true
}
