import React,{useState} from 'react'
import Navbar from '../Navbar/Navbar'
import Sidebar from '../Sidebar/Sidebar'
import { AboutObj } from './MobileInfoSection/Data'
import HeroSection from './MobileHeroSection/MobileHeroSection'
import InfoSection from './MobileInfoSection/MobileInfoSection'
import Contact from '../Contact/Contact'
import Footer from '../Footer/Footer'

function Mobile() {
    const [isOpen,SetIsOpen] = useState(false)

    const toggle = () =>{
        SetIsOpen(!isOpen)   
    }    

    return (
        <>
         <Sidebar isOpen={isOpen} toggle={toggle}/>
         <Navbar toggle={toggle}/>
         <HeroSection/> 
         <InfoSection {...AboutObj}/>   
         <Contact/>   
         <Footer/>   
        </>
    )
}

export default Mobile
