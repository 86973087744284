import React,{useState} from 'react'
// import {animateScroll as scroll} from "react-scroll"
import {FaFacebook,FaLinkedin,FaTwitter,FaEnvelope,FaPhone, FaInstagramSquare} from 'react-icons/fa'
import {Button} from "../../ButtonElements"

import {
    FooterContainer,
    FooterLinksContainer,
    FooterWrap,
    FooterLinkItems,
    FooterLinksWrapper,
    FooterLinkHead,
    SocialMedia,
    SocialMediaWrap,
    SocialIcons,
    SocialIconLink,
    FooterBtnWrapper,
    ArrowForward,
    ArrowRight,
    FooterNumber,
    FooterNumber3,
    WebsiteRights
} from "./FooterElements"

function Footer() {
    const [hover,setHover] = useState(false)

    const onHover = ()=>{
        setHover(!hover)
    } 

    // const toggleHome = () =>{
    //     scroll.scrollToTop();
    // }

    return (
        <>
        <FooterContainer>
            <FooterWrap>
                <FooterLinksContainer>
                    <FooterLinksWrapper>
                    <SocialMedia> 
                    <SocialMediaWrap>
                        <SocialIcons>
                            <SocialIconLink href="https://https://www.facebook.com/fred.vuni.1" target="_blank" aria-label="Facebook">
                                <FaFacebook/>
                            </SocialIconLink>

                            <SocialIconLink href="https://github.com/FREDVUNI/" target="_blank" aria-label="Instagram">
                                <FaInstagramSquare/>
                            </SocialIconLink>

                            <SocialIconLink href="https://www.linkedin.com/in/fred-vuni-3028a721b/?originalSubdomain=ug" target="_blank" aria-label="LinkedIn">
                                <FaLinkedin/>
                            </SocialIconLink>

                            <SocialIconLink href="https://twitter.com/Fredvuni" target="_blank" aria-label="Twitter">
                                <FaTwitter/>
                            </SocialIconLink>
                        </SocialIcons>
                    </SocialMediaWrap>
                </SocialMedia>
                        <FooterLinkItems>
                            <FooterLinkHead>Let's Talk </FooterLinkHead>
                        </FooterLinkItems>
                        <FooterBtnWrapper>
                            <Button to="about"
                                onMouseEnter={onHover}    
                                onMouseLeave={onHover}
                                primary='true'
                                dark='true'>
                                Make an enquiry {hover ? <ArrowForward/>:<ArrowRight/>}
                            </Button>
                        </FooterBtnWrapper>
                    </FooterLinksWrapper>
                </FooterLinksContainer>
            </FooterWrap>  
            <FooterNumber>
                <FooterWrap>
                <FooterNumber3>
                    <span><FaPhone/> +256 788 337 833</span>
                    <span><FaEnvelope/> info@rafahgroup</span>
                </FooterNumber3>
                </FooterWrap>
            </FooterNumber> 
                <WebsiteRights>
                  {new Date().getFullYear()} © Rafah group, All rights reserved.
                </WebsiteRights>  
        </FooterContainer> 
        </>
    )
}

export default Footer
