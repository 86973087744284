import styled from "styled-components"

export  const ProductsContainer = styled.div`
    height:500px;
    display:flex;
    flex-direction:column; 
    justify-content: center;  
    align-items:center;
    /* background:#010606; */
    background:#f9f9f9;

    @media  screen and (max-width:768px){
        height:1200px;
    }
    
    @media  screen and (max-width:480px){
        height:1300px;
    } 
`;

export  const ProductsWrapper = styled.div`
    max-width:1000px;
    margin:0 auto;
    display:grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items:center;
    grid-gap:16px;
    padding:0 50px;

    @media  screen and (max-width:1000px){
        grid-template-columns:1fr 1fr;
        
    } 
    @media  screen and (max-width:768px){
        grid-template-columns:1fr;
         
    } 
`;

export  const ProductsCard = styled.div`
    background: #fff;
    -webkit-box-shadow: 0px 42px 29px -31px rgb(0 0 0 / 5%);
    -moz-box-shadow: 0px 42px 29px -31px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 42px 29px -31px rgb(0 0 0 / 5%);
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    border-radius: 4px;
`;

export  const ProductsIcon = styled.img`
    max-width: 100%;
    width:100%;
    box-shadow: 0 2px 15px 0 rgb(0 0 0 / 6%);
`;

export  const ProductsH1 = styled.h1`
   color: #ff0000;
    font-size: 18px;
    line-height: 16px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 30px;

   @media  screen and (max-width:480px){
        font-size:2rem;
    } 
`;

export const ProductsH2 = styled.h2`
   font-size: 1rem;
   margin-bottom: 10px;
`;

export const ProductsP = styled.p`
   font-size: 1rem;
   text-align: left;

   @media  screen and (max-width:768px){
        text-align: left;
         
    } 

   @media  screen and (max-width:480px){
        text-align: left;
    } 
`;
