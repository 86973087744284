import React,{useState,useRef,useEffect} from 'react';
// import {Button} from "../ButtonElements"

import {
    HeroSection,
    HeroWrapper,
    Heroslide,
    HeroImage,
    HeroContent,
    HeroBtnWrapper,
    // ArrowForward,
    // ArrowRight,
    Heroslider,
    SliderButtons,
    PrevArrow,
    NextArrow,
} 
from'./HeroElements';
const Hero = ({slides}) => {
    // const [hover,setHover] = useState(false)

    // const onHover = ()=>{
    //     setHover(!hover)
    // }
    const [current ,setCurrent ] = useState(0)
    const length = slides.length;
    const timeout = useRef(null);

    useEffect(()=>{
        const nextslide = () =>{
            setCurrent(current=>(current === length - 1 ? 0 : current + 1));
        }
        timeout.current = setTimeout(nextslide,3000);
        return function (){
            if(timeout.current){
                clearTimeout(timeout.current);
            }
        }
    },[current,length])

    const nextSlide = () =>{
        setCurrent(current === length - 1 ? 0 : current + 1);
        console.log(current);
    }

    const prevSlide = () =>{
        setCurrent(current === 0 - 1 ? 0:current - 1)
        console.log(current);
    }
    if(!Array.isArray(slides) || slides.length <= 0){
        return null
    }
  return (
    <HeroSection>
        <HeroWrapper>
            {slides.map((slide,index)=>{
                return(
                    <Heroslide key={index} id="home">
                        {index === current && (
                            <Heroslider>
                            <HeroImage src={slide.Image} alt={slide.alt}/>
                                <HeroContent>
                                    <h1>{slide.title}</h1>
                                    <HeroBtnWrapper>
                                    {/* <Button to="about"
                                        onMouseEnter={onHover}    
                                        onMouseLeave={onHover}
                                        primary='true'
                                        dark='true'>
                                        view project {hover ? <ArrowForward/>:<ArrowRight/>}
                                    </Button> */}
                                </HeroBtnWrapper>
                                </HeroContent>
                            </Heroslider>
                        )}
                    </Heroslide>
                )
            })}
            <SliderButtons>
                <PrevArrow onClick={prevSlide}/>
                <NextArrow onClick={nextSlide}/>
            </SliderButtons>
        </HeroWrapper>
    </HeroSection>
  );
};

export default Hero;
