import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import {ClientContainer,ClientWrapper,ClientH1} from './ClientsElements.js'

import Icon1 from '../../images/clients/1.jpg'
import Icon2 from '../../images/clients/2.jpg'
import Icon3 from '../../images/clients/3.jpg'
import Icon4 from '../../images/clients/4.jpg'
import Icon5 from '../../images/clients/5.jpg'
import Icon6 from '../../images/clients/6.jpg'
import Icon7 from '../../images/clients/7.jpg'
import Icon8 from '../../images/clients/8.jpg'
import Icon9 from '../../images/clients/9.jpg'
import Icon10 from '../../images/clients/10.jpg'
import Icon11 from '../../images/clients/11.jpg'
import Icon12 from '../../images/clients/12.jpg'

const Clients = () => { 
  const options = {
    items: 1,
    nav: true,
    rewind: true,
    lazyLoad: true,
    autoplay: true
  };
  return (
    <ClientContainer id="clients">
      <ClientH1>Partners</ClientH1>
      <ClientWrapper>
      <OwlCarousel className="owl-theme" options={options} margin={4} loop animateOut={'fadeOut'}
        animateIn={'flipInX'}>
        <div className="item">
          <img src={Icon1} alt="icon1" />
        </div>
        <div className="item">
          <img src={Icon2} alt="icon2" />
        </div>
        <div className="item">
          <img src={Icon3} alt="icon3" />
        </div>
        <div className="item">
          <img src={Icon4} alt="icon4" />
        </div>
        <div className="item">
          <img src={Icon5} alt="icon5" /> 
        </div>
        <div className="item">
          <img src={Icon6} alt="icon6" />
        </div>
        <div className="item">
          <img src={Icon7} alt="icon7" />
        </div>
        <div className="item">
          <img src={Icon8} alt="icon8" />
        </div>
        <div className="item">
          <img src={Icon9} alt="icon9" />
        </div>
        <div className="item">
          <img src={Icon10} alt="icon10" />
        </div>
        <div className="item">
          <img src={Icon11} alt="icon11" />
        </div>
        <div className="item">
          <img src={Icon12} alt="icon12" />
        </div>
      </OwlCarousel>
      </ClientWrapper>
    </ClientContainer>
  );
};

export default Clients;

