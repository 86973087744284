export const AboutObj ={
    id:'products',
    lightBg:true,
    lightText:false,
    lightTextDesc:false,
    topLine:'',
    headLine:"Painting The Future",
    description:"Aerial paint provides you with the opportunity to “Paint your Future” as you make all your adorable color choices with ease.",
    description2:"For durable & satisfactory finishings on both interior and exterior walls, For high quality emulsion coatings that are durable and really easy to clean, For bright and water proof exterior wall paintings. We are painting the future with excellet emulsion paint solutions",
    buttonLabel:"Get Started",
    imgStart:true,
    img:require('../images/waterproof.jpg'),
    alt:'paint',
    dark:false,
    primary:false,
    darkText:true
}
