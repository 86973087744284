import React from 'react'
import './company.css'
import Icon1 from '../../images/rafahcomm.jpeg'
import Icon2 from '../../images/rafahbuilders.jpeg'
 import Icon3 from '../../images/rafahenergy.jpg'
import {Link} from 'react-router-dom'  

const Company = () => {
   return (
      <div className="main_content" id="companies">
            <h3>Rafah Group</h3>
        <div className="wrapper-c">
        <div className="card">
            <div className="card_img">
                <img src={Icon1} alt="company"/>
            </div>
            <div className="card_header">
                <h2><Link to="/rafahcommunications">Rafah Communications</Link></h2>
                <p><b>ICT | Electrical | Telecommunication</b></p>
            </div>
        </div>

        <div className="card">
            <div className="card_img">
                <img src={Icon2} alt="company"/>
            </div>
            <div className="card_header">
                <h2><Link to="/rafahbuilders">Rafah Builders</Link></h2>
                <p><b>Construction | Real estate | Paint & Accessories</b></p>
            </div>
        </div>

        <div className="card">
            <div className="card_img">
                <img src={Icon3} alt="company"/>
            </div>
            <div className="card_header">
                <h2><Link to="/rafahenergy">Rafah Energy</Link></h2>

                <p><b>Energy | Oil | Gas</b></p>
            </div>
        </div>
        </div>
        </div>
   )
}

export default Company
