import React from 'react'

import Icon1 from '../images/construction.svg'
import Icon2 from '../images/realestate.svg'
import Icon3 from '../images/painting.svg'

import {
    ServicesContainer,
    ServicesH1, 
    ServicesWrapper,
    ServicesCard,
    ServicesH2,
    ServicesP
}from './ServiceElements' 
import './service.css'

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Services = () => {
    return (
        <ServicesContainer id="services">
            <ServicesH1>Services</ServicesH1>
                <ServicesWrapper>
                    <ServicesCard>
                        <LazyLoadImage src={Icon1} className='imageLoad' effect='blur'/>
                        <ServicesH2>Construction & Construction materials</ServicesH2>
                        <ServicesP>We offer Construction Services, builders and all building materials and machinery</ServicesP>
                    </ServicesCard>

                    <ServicesCard>
                        <LazyLoadImage src={Icon2} className='imageLoad' effect='blur'/>
                        <ServicesH2>Real Estates</ServicesH2>
                        <ServicesP>We buy and sell well furnished houses, plots and land at affordable prices around and outside Kampala</ServicesP>
                    </ServicesCard>

                    <ServicesCard>
                        <LazyLoadImage src={Icon3} className='imageLoad' effect='blur'/>
                        <ServicesH2>Paints & Accessories</ServicesH2>
                        <ServicesP>We provide painting services for both interior and exterior walls for all types of housing, manufacture and sell paints and painting accessories.</ServicesP>
                    </ServicesCard>
                </ServicesWrapper>
        </ServicesContainer>
    )
}

export default Services
