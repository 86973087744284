import React,{useState} from 'react'
import Navbar from './Navbar/Navbar'
import Sidebar from './Sidebar/Sidebar'
import { AboutObj } from './InfoSection/Data'
import HeroSection from './HeroSection/HeroSection'
import InfoSection from './InfoSection/InfoSection'
import Services from './Services/Services'
import Contact from './Contact/Contact'
import Products from './Products/Products'
import Projects from './RafahProjects/RafahProjects'
import Footer from './Footer/Footer'

function Home() {
    const [isOpen,SetIsOpen] = useState(false)

    const toggle = () =>{
        SetIsOpen(!isOpen)   
    }    

    return (
        <>
         <Sidebar isOpen={isOpen} toggle={toggle}/>
         <Navbar toggle={toggle}/>
         <HeroSection/> 
         <InfoSection {...AboutObj}/>   
         <Products/>   
         <Services/>   
         <Projects/>   
         <Contact/>   
         <Footer/>   
        </>
    )
}

export default Home
