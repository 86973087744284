import React,{useState} from 'react'
import { SliderData } from './Hero/SliderData'
import Navbar from './Navbar/Navbar'
import Sidebar from './Sidebar/Sidebar'
import { AboutObj } from './InfoSection/Data'
 import Hero from './Hero/Hero'
import Paint from './Paint/Paint'
import Ad from './Ad/Ad'
import Projects from './Projects/Projects'
import InfoSection from './InfoSection/InfoSection'
import Services from './Services/Services'
import Contact from './Contact/Contact'
import Footer from './Footer/Footer'


function Home(props) {
    const [isOpen,SetIsOpen] = useState(false)

    const toggle = () =>{
        SetIsOpen(!isOpen)   
    }    

    return (
        <>
         <Sidebar isOpen={isOpen} toggle={toggle}/>
         <Navbar toggle={toggle}/>
         <Hero slides={SliderData}/> 
          <Services/>   
         <InfoSection {...AboutObj}/>
         <Ad/>     
         <Paint/>  
         <Projects/> 
         <Contact/>    
         <Footer/>   
        </>
    )
}

export default Home
