import React,{useState} from 'react'
import {
HeroContainer,
HeroBg,
ImgBg,
HeroContent,
HeroH1,
HeroP,
HeroBtnWrapper,
ArrowForward,
ArrowRight,
HeroButton
} from "./ElectricalHeroSectionElements"
import Image from "../../../../images/12.jpeg"

function HeroSection() {
    const [hover,setHover] = useState(false)

    const onHover = ()=>{
        setHover(!hover)
    }

    return (
        <HeroContainer id="home">
            <HeroBg>
                <ImgBg src={Image} type="images/jpg"/>
            </HeroBg>
            <HeroContent>
                {/* <HeroH1>welcome to rafah communications </HeroH1> */}
                <HeroP>Electrical Installation</HeroP>
                {/* <HeroBtnWrapper>
                    <HeroButton to="about"
                        onMouseEnter={onHover}    
                        onMouseLeave={onHover}
                        primary='true'
                        dark='true'>
                        Read more {hover ? <ArrowForward/>:<ArrowRight/>}
                    </HeroButton>
                </HeroBtnWrapper> */}
            </HeroContent>
        </HeroContainer>
    )
}

export default HeroSection
