import React,{useRef} from 'react'
import "./contact.css"
// import emailjs from "emailjs-com"

const Contact = () => {
    const formRef = useRef()
    // const [done,SetDone] = useState(false)

    // const SubmitHandler = (e)=>{
    //     e.preventDefault();

    //     emailjs
    //         .sendForm(
    //             "service_bd8yafo",
    //             "template_gkngc3a",
    //             formRef.current,
    //             "user_enQpnH8nsOJMTWNKRCELa",
    //         )
    //         .then(
    //             (result)=>{
    //                 console.log(result.text);
    //                 SetDone(true)
    //             },
    //             (error)=>{
    //                 console.log(error.text);
    //             }
    //         )
        
    // }
    return (
        <div className="c" id="contact">
            <div className="bg"></div>   
            <div className="c-wrapper">
                <div className="c-left">
                    <p className="topLine">contact us</p>
                    <h1 className="title">Leave us a message</h1>
                    <div className="col-md-5 d-flex align-items-stretch">
            <div className="info-wrap bg-primary w-100 p-lg-5">
               <div className="dbox w-100 d-flex align-items-start">
                  <div className="text pl-3">
                     <span><span className='contactDetails'>Address:</span> Mukalazi Road, Bukoto 1</span>
                  </div>
               </div>
               <div className="dbox w-100 d-flex align-items-center">
                  <div className="text pl-3">
                     <span><span className='contactDetails'>Phone:</span> +256 740 825 555 | +256 740 825 556</span>
                  </div>
               </div>
               <div className="dbox w-100 d-flex align-items-center">
                  <div className="text pl-3">
                     <span><span className='contactDetails'>Email:</span> aerial@rafahgroup.com | info@rafahgroup.com</span>
                  </div>
               </div>
               <div className="dbox w-100 d-flex align-items-center">
                  <div className="text pl-3">
                     <span><span className='contactDetails'>Website:</span> www.rafahgroup.com</span>
                  </div>
               </div>
            </div>
         </div>
                </div>
                <div className="c-right">
                    {/* <form ref={formRef} onSubmit={SubmitHandler}> */}
                    <form ref={formRef} >
                        <div className="form-group">
                            <input type="text" name="name" className="form-control" placeholder="Enter your name" required/>
                        </div>
                        <div className="form-group">
                            <input type="email" name="email" className="form-control" placeholder="Enter your email address" required/>
                        </div>
                        <div className="form-group">
                            <textarea className="form-control" rows="5" name="message" id="message" placeholder="How can we help you?" aria-label="Tell me more about your project, needs, and timeline or just make an inquiry." required="" data-msg="Please enter a reason."></textarea>
                        </div>
                        <div className="form-group">
                            <button type="submit" className="btn">
                                Send message
                            </button>
                        </div>
                    </form>
                    {/* {done && 
                        <div className='alert alert-success'>Your message has been sent.Thank you,i'll get back to you as soon as i can.</div>
                    } */}
                </div>
            </div>
        </div>
    )
}

export default Contact
