import React,{useState} from 'react' 
import Navbar from '../components/Navbar/Navbar'
import Sidebar from '../components/Sidebar/Sidebar'
import { AboutObj } from '../components/InfoSection/Data'
import HeroSection from '../components/HeroSection/HeroSection'
import InfoSection from '../components/InfoSection/InfoSection'
import Company from '../components/Company/Company'
import Clients from '../components/Clients/Clients'
import Contact from '../components/Contact/Contact'
import Footer from '../components/Footer/Footer'

function Home() {
    const [isOpen,SetIsOpen] = useState(false)

    const toggle = () =>{
        SetIsOpen(!isOpen)
    }

    return (
        <>
         <Sidebar isOpen={isOpen} toggle={toggle}/>
         <Navbar toggle={toggle}/>
         <HeroSection/> 
         <InfoSection {...AboutObj}/> 
         <Company/> 
         <Clients/>   
         <Contact/>   
         <Footer/>   
        </>
    )
}

export default Home
