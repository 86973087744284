import styled from "styled-components";
// import {Link} from "react-router-dom"
import { Link as LinkS} from 'react-scroll'


export const Button = styled(LinkS)`
    background:${({primary})=>(primary ? "transparent":"#")};  
    white-space:nowrap;
    padding:${({big})=>(big ? '14px 48px':'12px 30px')};
    color:${({dark})=>(dark ? "#fff":"transparent")};
    font-size:${({fontBig})=>(fontBig ? '20px':'16px')};
    outline:none;
    cursor:pointer;
    display: flex;
    justify-content:center; 
    align-items: center;
    transition: all 0.2s ease-in-out;
    text-decoration:none;
    
    font-family: Overpass, sans-serif;
    font-size: 19px;
    font-weight: 600;
    line-height: 12px;
    border-style: solid;
    border-width: 1px;
    border-color: #FF0000;
    padding: 20px 20px;

    &:hover{
        transition: all 0.2s ease-in-out;
        background:${({primary})=>(primary ? "#FF0000":"transparent")};
        text-decoration:none;
    }
`;