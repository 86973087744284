import React from 'react';
import './projects.css'
import image1 from '../images/1.jpeg'
import image2 from '../images/2.jpeg'
import image3 from '../images/3.jpeg'
import image4 from '../images/4.jpeg'
import image5 from '../images/5.jpeg'

import {ClientH1} from './ProjectsElements'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Projects = () => {
  return <div className='containers' id="projects">
      <ClientH1>Completed Projects</ClientH1>
      <div className='wrapper'>
      <div className='box'>
          <div className='imgBx'>
            <LazyLoadImage alt="title" src={image1}/>
          </div>
          <div className='content'>
              <div>
                  <h2>Interior Painting</h2>
                    <p>
                      We are that amazing team you wish to work with
                    </p>
              </div>
          </div>
      </div>
      <div className='box'>
          <div className='imgBx'>
            <LazyLoadImage alt="title1" src={image2}/>
          </div>
          <div className='content'>
              <div>
                  <h2>Interior Painting</h2>
                    <p>
                      We are that amazing team you wish to work with
                    </p>
              </div>
          </div>
      </div>
      <div className='box'>
          <div className='imgBx'>
            <LazyLoadImage alt="title2" src={image3}/>
          </div>
          <div className='content'>
              <div>
                  <h2>Interior Painting</h2>
                    <p>
                      We are that amazing team you wish to work with
                    </p>
              </div>
          </div>
      </div>
      <div className='box'>
          <div className='imgBx'>
            <LazyLoadImage alt="title3" src={image4}/>
          </div>
          <div className='content'>
              <div>
                  <h2>Exterior Painting</h2>
                    <p>
                      We are that amazing team you wish to work with
                    </p>
              </div>
          </div>
      </div>
      <div className='box'>
          <div className='imgBx'>
            <LazyLoadImage alt="title3" src={image5}/>
          </div>
          <div className='content'>
              <div>
                  <h2>Interior Painting</h2>
                    <p>
                      We are that amazing team you wish to work with
                    </p>
              </div>
          </div>
      </div>
      <div className='box'>
          <div className='imgBx'>
            <LazyLoadImage alt="title3" src={image1}/>
          </div>
          <div className='content'>
              <div>
                  <h2>Interior Painting</h2>
                    <p>
                      We are that amazing team you wish to work with
                    </p>
              </div>
          </div>
      </div>
      </div>
  </div>;
};

export default Projects;
