import React from 'react'
import Fade from "react-reveal/Fade"
import F18 from '../images/dronez.jpeg'
import "./products.css"
import Cctv from '../images/rafahcam.jpeg'
import Scanner from '../images/centrion.jpeg'
import Fire from '../images/bomb.jpeg'

import {
    ProductsContainer,
    ProductsH1,
    ProductsWrapper
}from './ProductElements' 
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './products.css'

const Products = () => {
    return (
        <ProductsContainer id="products">
            <ProductsH1>Products</ProductsH1>
                <ProductsWrapper>
                 <Fade bottom cascade>
                 <div class="product-card">
                    <div class="product-tumb">
                        <LazyLoadImage src={Cctv} alt="icon" effect='blur'/>
                    </div>
                    <div class="product-details">
                        <span class="product-catagory">We supply, maintain and install cctv cameras for home and industrial survillance</span>
                        <h4>CCTV Cameras</h4>
                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vero, possimus nostrum!</p>
                        <div class="product-bottom-details">
                            <div class="product-price"><small>$96.00</small>$230.99</div>
                            <div class="product-links">
                                <a href="rafahgroup"><i class="fa fa-heart"></i></a>
                                <a href="rafahgroup"><i class="fa fa-shopping-cart"></i></a>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div class="product-card">
                    <div class="product-tumb">
                        <LazyLoadImage src={F18} alt="icon" effect='blur'/>
                    </div>
                    <div class="product-details">
                        <span class="product-catagory">We supply, hire and train how to use Agriculture drones</span>
                        <h4>Agriculture Drones</h4>
                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vero, possimus nostrum!</p>
                        <div class="product-bottom-details">
                            <div class="product-price"><small>$96.00</small>$230.99</div>
                            <div class="product-links">
                                <a href="rafahgroup"><i class="fa fa-heart"></i></a>
                                <a href="rafahgroup"><i class="fa fa-shopping-cart"></i></a>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div class="product-card">
                    <div class="product-tumb">
                        <LazyLoadImage src={Scanner} alt="icon" effect='blur'/>
                    </div>
                    <div class="product-details">
                        <span class="product-catagory">We supply and install bomb detectors in buildings and all public places</span>
                        <h4>Bomb Detectors</h4>
                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vero, possimus nostrum!</p>
                        <div class="product-bottom-details">
                            <div class="product-price"><small>$96.00</small>$230.99</div>
                            <div class="product-links">
                                <a href="rafahgroup"><i class="fa fa-heart"></i></a>
                                <a href="rafahgroup"><i class="fa fa-shopping-cart"></i></a>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div class="product-card">
                    <div class="product-tumb">
                    <LazyLoadImage src={Fire} alt="icon" effect='blur'/>
                    </div>
                    <div class="product-details">
                        <span class="product-catagory">We install, supply and maintain Centrion Locks for doors and gate</span>
                        <h4>Centrion locks</h4>
                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vero, possimus nostrum!</p>
                        <div class="product-bottom-details">
                            <div class="product-price"><small>$96.00</small>$230.99</div>
                            <div class="product-links">
                                <a href="rafahgroup"><i class="fa fa-heart"></i></a>
                                <a href="rafahgroup"><i class="fa fa-shopping-cart"></i></a>
                            </div>
                        </div> */}
                    </div>
                </div>
                 </Fade>
                </ProductsWrapper>
        </ProductsContainer>
    )
}

export default Products
