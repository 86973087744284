import React from 'react';
import './ad.css'

const Ad = () => {
  return (
  <section className="section">
       <div className="row">
          <div className="col-lg-7 mb-lg-30">
             <div className="section-title text-left mb-0">
                <h4 className="title text-white">Matt Emulsion Paint Weatherguard</h4>
                <p className="text-white">For bright and water proof exterior wall paintings.</p>
                <a className="sigma_btn-custom" href="">Learn More</a>
             </div>
          </div>
       </div>
  </section>
  )
  ;
};

export default Ad;
