export const AboutObj ={
    id:'about',
    lightBg:true,
    lightText:false,
    lightTextDesc:false,
    topLine:'',
    headLine:"Who We Are...",
    description:"Rafah Energy is committed to providing reliable and quality supply service to meet our client utmost requirements.",
    description2:"We currently offer the following products",
    description3:"1) Liquidified Petrolium Gas (LPG)",
    description4:"2) Automotive & Industrial Lubricatnts",
    description5:"3) HFO",
    buttonLabel:"Get Started",
    imgStart:true,
    img:require('../../../images/energy.jpg'),
    alt:'office',
    dark:false,
    primary:false,
    darkText:true
}
