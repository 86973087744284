import styled from 'styled-components'
import { Link as LinkS} from 'react-scroll'
import {MdKeyboardArrowRight,MdArrowForward} from "react-icons/md"

export const FooterContainer = styled.footer`
    background-color: rgb(27, 27, 27);
    
`;
export const FooterWrap = styled.div`
    padding:48px 24px;
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    max-width:1100px;
    margin:0 auto;
`;
export const Img = styled.img`
    height:200px;
    width:200px;
`;
export const FooterLinksContainer = styled.div`
    display: flex;
    justify-content: center;

    @media screen and (max-width:820px){
        padding-top: 32px;
    }
`;
export const FooterBtnWrapper = styled.div`
    margin-top: 55px;
    display:flex;
    flex-direction: column;
    align-items: center;
`;

export const ArrowForward = styled(MdArrowForward)`
    margin-left:8px;
    font-size:20px;
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
    margin-left:8px;
    font-size:20px;
`;
export const FooterLinksWrapper = styled.div`
    display: flex;

    @media screen and (max-width:820px){
        flex-direction: column;
    }
`;
export const FooterLinkItems = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin:16px;
    text-align:left;
    width:600px;
    box-sizing:border-box;
    color:#fff;

    @media screen and(max-width:420px){
        margin:0;
        padding:10px;
        width:100%;
    }
`;
export const FooterLinkTitle = styled.h1`   
    font-size:18px;
    margin-bottom:16px;
    font-weight:600;
`;
export const FooterLinkHead = styled.h2`
    color: rgb(255, 255, 255);
    font-family: Overpass, sans-serif;
    font-size: 48px;
    font-weight: 800;
    line-height: 58px;
    letter-spacing: -1px;
    margin-bottom:16px;

    @media screen and (max-width:820px){
        justify-content:center;
        margin-left:200px;
    }
`;
export const FooterLink = styled(LinkS)`
    color:#fff;
    text-decoration:none;
    margin-bottom:0.5rem;
    font-size:18px;
    cursor:pointer;

    &:hover{
        color:#84ba3f;
        transition:0.3s ease-out;
    }
`;
export const FooterNumber = styled.div`
color:#fff;
text-decoration:none;
background:#161616;
width:100%;
`;

export const FooterNumber3 = styled.div`
color:#fff;
text-decoration:none;
font-size:18px;
cursor:pointer;
display:flex;
justify-content: space-between;
width:100%;
`;

export const NumberFooter1 = styled.p`
    color:#fff;
    text-decoration:none;
    margin-bottom:0.5rem;
    font-size:18px;
    cursor:pointer;
    justify-content: flex-end;
    margin-left:0;

    &:hover{
        color:#84ba3f;
        transition:0.3s ease-out;
    }
`;
export const NumberFooter2 = styled.p`
    color:#fff;
    text-decoration:none;
    margin-bottom:0.5rem;
    font-size:18px;
    cursor:pointer;
    float:left;
    justify-content: flex-end

    &:hover{
        color:#84ba3f;
        transition:0.3s ease-out;
    }
`;
export const SocialMedia = styled.section`
    max-width: 420px;
    width:100%;

    @media screen and (max-width:820px){
        justify-content:center;
        margin-left:120px;
    }
`;

export const SocialMediaWrap = styled.div`
    display:flex;
    justify-content:space-between;
    align-items: center;
    max-width:1100px;
    margin:40px auto 0 auto;

    @media screen and (max-width:820px){
        flex-direction: column;
    }
`;

export const SocialLogo = styled(LinkS)`
    color:#fff;
    justify-self:start;
    cursor: pointer;
    text-decoration:none;
    font-size:1.5rem;
    display:flex;
    align-items: center;
    margin-bottom: 16px;
    font-weight:bold;
`;

export const WebsiteRights = styled.small`
margin-bottom:16px;
font-size: 14px;
letter-spacing: 1px;
margin-top:30px;
color: #ffffff;
font-family: Overpass, sans-serif;
font-size: 15px;
line-height: 24px;
background: #000;

padding:28px 24px;
display:flex;
flex-direction: column;
justify-content:center;
align-items: center;
margin:0 auto;

`;

export const SocialIcons = styled.div`
    display: flex;
    justify-content: space-between;
    align-items:center;
    width:250px;
    
`;

export const SocialIconLink = styled.a`
    color:#fff;
    font-size:18px;
    background-color: rgba(173, 173, 173, 0);
    border-style: solid;
    border-width: 1px;
    // border-color: rgb(233, 32, 79);
    border-radius: 50%;
    padding:15px;
    width: 1em;
    height: 1em;

    &:hover{
        color:#ff0000;
    }
`;