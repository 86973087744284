import {BrowserRouter as Router,Route,Routes} from 'react-router-dom'
import './App.css'
import Home from './pages';
import Rafahcomm from './components/rafahcomm';
import Rafahenergy from './components/rafahenergy';
import Rafahbuilders from './components/rafahbuilders';
import ScrollToTop from './components/ScrollToTop';

import Security from './components/rafahcomm/Pages/Security'
import Electrical from './components/rafahcomm/Pages/Electrical'
import Agric from './components/rafahcomm/Pages/Agric'
import Mobile from './components/rafahcomm/Pages/Mobile'
import Web from './components/rafahcomm/Pages/Web'
import Network from './components/rafahcomm/Pages/Network'

function App() {
  return (
    <Router>
      <ScrollToTop>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/rafahcommunications" element={<Rafahcomm/>} />
        <Route path="/rafahenergy" element={<Rafahenergy/>} />
        <Route path="/rafahbuilders" element={<Rafahbuilders/>} />

        <Route path="/rafahcommunications/security-systems" element={<Security/>} />
        <Route path="/rafahcommunications/electrical-installation" element={<Electrical/>} />
        <Route path="/rafahcommunications/agricultural-drones" element={<Agric/>} />
        <Route path="/rafahcommunications/mobile-app-development" element={<Mobile/>} />
        <Route path="/rafahcommunications/website-development" element={<Web/>} />
        <Route path="/rafahcommunications/cloud-computing-and-networking" element={<Network/>} />
      </Routes>
      </ScrollToTop>
    </Router>
  );
}

export default App;
