import styled from "styled-components"

export  const ServicesContainer = styled.div`
    height:1000px;
    display:flex;
    flex-direction:column; 
    justify-content: center;  
    align-items:center;
    /* background:#010606; */
    background:#fff;
   
    @media  screen and (max-width:768px){
        height:1200px;
        margin-top:700px;
        margin-bottom:800px;
    }
    
    @media  screen and (max-width:480px){
        height:1300px;
        margin-top:700px;
        margin-bottom:800px;
    } 
`;

export  const ServicesWrapper = styled.div`
    max-width:1000px;
    margin:0 auto;
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items:center;
    grid-gap:16px;
    padding:0 50px;

    @media  screen and (max-width:1000px){
        grid-template-columns:1fr 1fr;
        
    } 
    @media  screen and (max-width:768px){
        grid-template-columns:1fr;
         
    } 
`;

export  const ServicesCard = styled.div`
    background: #fff;
    padding: 40px 20px;
    -webkit-box-shadow: 0px 42px 29px -31px rgb(0 0 0 / 5%);
    -moz-box-shadow: 0px 42px 29px -31px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 42px 29px -31px rgb(0 0 0 / 5%);
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    border-radius: 4px;

    &:hover{
        transform: scale(1.02);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
    }
`;

export  const ServicesIcon = styled.img`
   height: 160px;
   width: 160px;
   margin-bottom: 10px;
`;

export  const ServicesH1 = styled.h1`
   color: #ff0000;
    font-size: 18px;
    line-height: 16px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 16px;

   @media  screen and (max-width:480px){
        font-size:2rem;
    } 
`;

export const ServicesH2 = styled.h2`
   font-size: 1rem;
   margin-bottom: 10px;
   color:#000;
`;

export const ServicesP = styled.p`
   font-size: 1rem;
   text-align: left;
   color: #000;

   @media  screen and (max-width:768px){
        text-align: left;
         
    } 

   @media  screen and (max-width:480px){
        text-align: left;
    } 
`;
