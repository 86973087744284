import React from 'react'

import Icon1 from '../images/security.svg'
import Icon2 from '../images/electrical.svg'
import Icon3 from '../images/drones.svg'
import Icon4 from '../images/mobile.svg'
import Icon5 from '../images/web.svg'
import Icon6 from '../images/network.svg'

import {
    ServicesContainer,
    ServicesH1,
    ServicesWrapper,
    ServicesCard,
    ServicesH2,
    ServicesP
}from './ServiceElements' 
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './services.css'
import {Link} from 'react-router-dom'  

const Services = () => {
    return (
        <ServicesContainer id="services">
            <ServicesH1>Services</ServicesH1>
                <ServicesWrapper>
                    <Link to="/rafahcommunications/security-systems" className='serviceLink'>
                    <ServicesCard>
                        <LazyLoadImage effect='blur' className='serviceproduct' src={Icon1}/>
                        <ServicesH2>Security Systems</ServicesH2>
                        <ServicesP>We design, supply, install and maintain all security systems within domestic, corporate and industrial markets</ServicesP>
                    </ServicesCard>
                    </Link>

                    <Link to="/rafahcommunications/electrical-installation" className='serviceLink'>
                    <ServicesCard>
                        <LazyLoadImage effect='blur' className='serviceproduct' src={Icon2}/>
                        <ServicesH2>Electrical Installation</ServicesH2>
                        <ServicesP>We have vast experience in the Electrical Services in Restaurants, Supermarkets, Banks, Offices etc</ServicesP>
                    </ServicesCard>
                    </Link>

                    <Link to="/rafahcommunications/agricultural-drones" className='serviceLink'>
                    <ServicesCard>
                        <LazyLoadImage effect='blur' className='serviceproduct' src={Icon3}/>
                        <ServicesH2>Agriculture Drones</ServicesH2>
                        <ServicesP>Increase work efficiency with our spray drones designed for spraying crop pesticides & fertilizers, seeds etc.</ServicesP>
                    </ServicesCard>
                    </Link>

                    <Link to="/rafahcommunications/mobile-app-development" className='serviceLink'>
                    <ServicesCard>
                        <LazyLoadImage effect='blur' className='serviceproduct' src={Icon4}/>
                        <ServicesH2>Mobile App dev't</ServicesH2>
                        <ServicesP>We design and develop iOS & Android applications that provode a great mobile app experience.</ServicesP>
                    </ServicesCard>
                    </Link>

                    <Link to="/rafahcommunications/website-development" className='serviceLink'>
                    <ServicesCard>
                        <LazyLoadImage effect='blur' className='serviceproduct' src={Icon5}/>
                        <ServicesH2>Website dev't</ServicesH2>
                        <ServicesP>We design and develop highly responsive websites ranging from static, dynamic & E-commerce sites.</ServicesP>
                    </ServicesCard>
                    </Link>

                    <Link to="/rafahcommunications/cloud-computing-and-networking" className='serviceLink'>
                    <ServicesCard>
                        <LazyLoadImage effect='blur' className='serviceproduct' src={Icon6}/>
                        <ServicesH2>Cloud Computing & Networking</ServicesH2>
                        <ServicesP>We provide everything from infrastructure (servers, storage and networking) to applications, databases, analytics, intelligence, & many more</ServicesP>
                    </ServicesCard>
                    </Link>
                </ServicesWrapper>
        </ServicesContainer>
    )
}

export default Services
