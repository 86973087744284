export const AboutObj ={
    id:'about',
    lightBg:true,
    lightText:false,
    lightTextDesc:false,
    topLine:'',
    headLine:"CCTV CAMERAS",
    headLine2:"BIOMETRIC ACCESS CONTROL SYSTEMS",
    headLine3:"INTRUDER ALARM SYSTEMS",
    headLine4:"FIRE ALARM SYSTEMS",
    description:"We offer the wide range of Modern & Advanced weather-proof CCTV surveillance cameras exclusively designed for both indoor as well as outdoor security & surveillance. These include: PTZ CAMERAS, WIRELESS KITS, IP CAMERAS Plus Remote Survillance using any hand held gadget. e.t.c. We are here to watch your back - all day, from all directions.",
    description2:"Secure, simple yet robust! Access control refers to the practice of restricting entrance to a property, a building, or a room to authorized persons. Physical access control can be achieved by a human (a guard, bouncer, or receptionist), through mechanical means such as locks and keys, or through technological means such as access control systems. Within these environments, physical key management may also be employed as a means of further managing and monitoring access to mechanically keyed areas or access to certain small assets. Physical access control is a matter of who, where, and when. An access control system determines who is allowed to enter or exit, where they are allowed to exit or enter, and when they are allowed to enter or exit",
    description3:" These systems are designed to detect intrusion or un authorized entry into the building or area. Security alarms are used in residential commercial buildings, industrial and military properties for protection against burglar (theft) or property damage as well as personal protection against intruders. A variety of vibration, motion sensors are installed & configured to trigger an alarm or send an SMS to the configured mobile number each time the circuit is broken by an intrude",
    description4:"A fire alarm system has a number of devices working together to detect and warn people through visual and audio appliances when smoke, fire, carbon monoxide break outs happen. Types of fire systems (1) Convessional fire system(2) Addressable fire system.",
    buttonLabel:"Get Started",
    imgStart:true,
    img1:require('../../images/rafahcam.jpeg'),
    img2:require('../../images/access1.png'),
    img3:require('../../images/intruder.png'),
    img4:require('../../images/fire.png'),
    alt:'office',
    dark:false,
    primary:false,
    darkText:true
}
