import React from 'react'
import ProfilePDF from '../../../../images/profile/rafah.pdf';
import {
    InfoContainer,
    InfoWrapper,
    InfoRow,
    Column1,
    TextWrapper,
    TopLine,
    Heading,
    SubTitle,
    Column2,
    ImgWrap,
    
} from "./WebInfoSectionElements"
import './webinfo.css'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function InfoSection({lightBg,id,imgStart,topLine,lightText,headLine,darkText,buttonLabel,img,alt,primary,dark,dark2,description,description2}) {
    return (
        <>   
        <InfoContainer lightBg={lightBg} id={id}>
            <InfoWrapper>
                <InfoRow imgStart={imgStart}>  
                    <Column1>
                        <TextWrapper>
                            <TopLine>{topLine}</TopLine>
                            <Heading lightText={lightText}>{headLine}</Heading>
                            <SubTitle darkText={darkText}>{description}</SubTitle>
                            <SubTitle darkText={darkText}>{description2}</SubTitle>
                        </TextWrapper>
                    </Column1>
                    <Column2>
                        <ImgWrap>
                            <LazyLoadImage src={img} alt={alt} effect="blur" className='infoImage1'/>
                        </ImgWrap>
                    </Column2>    
                </InfoRow>    
            </InfoWrapper>    
        </InfoContainer> 
        </>
    )
}

export default InfoSection
