export const AboutObj ={
    id:'about',
    lightBg:true,
    lightText:false,
    lightTextDesc:false,
    topLine:'',
    headLine:"Networking & Cloud Computing",
    description:"We provide everything from infrastructure (servers, storage and networking) to applications, databases, analytics, intelligence, & many more",
    description2:"We also provide telecommunication services and enable you easily transfer information. These include PABX Systems.",
    buttonLabel:"Get Started",
    imgStart:true,
    img:require('../../images/network.svg').default,
    alt:'office',
    dark:false,
    primary:false,
    darkText:true
}
