export const AboutObj ={
    id:'about',
    lightBg:true,
    lightText:false,
    lightTextDesc:false,
    topLine:'',
    headLine:"Agriculture Drones",
    description:"Increase your work efficiency with Rafah's spray drone designed for spraying crop pesticide & fertilizer, seeds & granules.",
    description2:"We design and develop iOS & Android applications that provode a great mobile app experience.",
    buttonLabel:"Get Started",
    imgStart:true,
    img:require('../../images/drone.png'),
    alt:'office',
    dark:false,
    primary:false,
    darkText:true
}
